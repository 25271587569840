import React, { useState } from 'react'
import { Link } from 'gatsby'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import theme from 'components/gatsby/theme'

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false)
    return (
        <React.Fragment>
            <Drawer
                anchor="left"
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
            >
                <List>
                    <ListItem style={{ paddingLeft: 36, paddingRight: 36 }}>
                        <Link
                            to="/"
                            activeStyle={{
                                color: theme.palette.primary.main,
                            }}
                        >
                            Home
                        </Link>
                    </ListItem>
                    <ListItem style={{ paddingLeft: 36, paddingRight: 36 }}>
                        <Link
                            to="/game"
                            activeStyle={{
                                color: theme.palette.primary.main,
                            }}
                        >
                            Game
                        </Link>
                    </ListItem>
                    <ListItem style={{ paddingLeft: 36, paddingRight: 36 }}>
                        <Link
                            to="/roles"
                            activeStyle={{
                                color: theme.palette.primary.main,
                            }}
                        >
                            Roles
                        </Link>
                    </ListItem>
                    <ListItem style={{ paddingLeft: 36, paddingRight: 36 }}>
                        <Link
                            to="/stages"
                            activeStyle={{
                                color: theme.palette.primary.main,
                            }}
                        >
                            Stages
                        </Link>
                    </ListItem>
                </List>
            </Drawer>
            <AppBar position="static">
                <Toolbar variant="dense">
                    <IconButton
                        onClick={() => setMenuOpen(true)}
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        color="inherit"
                        style={{ flexGrow: 1 }}
                    >
                        <Link to="/">werewolf-night.com</Link>
                    </Typography>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    )
}

export default Header
