import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
    html {
        background: #d6d6d6;
    }

    body {
        margin: 0;
    }
    
    .day {
        background: #fdfdb0 !important;
        transition: all 8s ease;
    }

    .night {
        background: #0d0b4c !important;
        /* color: white; */
        transition: all 8s ease;
    }

    a {
        color: inherit;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        text-decoration: none;
    }
`

export default GlobalStyle
