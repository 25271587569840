import { createMuiTheme } from '@material-ui/core/styles'
import teal from '@material-ui/core/colors/teal'
import blueGrey from '@material-ui/core/colors/blueGrey'

const theme = createMuiTheme({
    palette: {
        primary: teal,
        secondary: blueGrey,
    },
    overrides: {
        MuiButton: {
            root: {
                textTransform: 'none',
            },
        },
    },
})

export default theme
