/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Header from 'components/gatsby/header'
import '../../i18n'
import GlobalStyle from './globalStyle'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from './theme'

function Tracking() {
    return (
        <Helmet>
            <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=G-KC9YNPGNSE"
            ></script>
            <script>
                {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-KC9YNPGNSE');`}
            </script>
        </Helmet>
    )
}

const Layout = ({ children, blank }) => {
    if (blank) {
        return (
            <ThemeProvider theme={theme}>
                <Tracking />
                <GlobalStyle />
                {children}
            </ThemeProvider>
        )
    }
    return (
        <ThemeProvider theme={theme}>
            <Tracking />
            <GlobalStyle />
            <Header />
            <div style={{ padding: 12, background: 'white' }}>
                <main>{children}</main>
                {/* <footer>
                    © {new Date().getFullYear()}, <span>Footer</span>
                </footer> */}
            </div>
        </ThemeProvider>
    )
}

Layout.defaultProps = {
    blank: false,
}
Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
